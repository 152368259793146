"use client";

import { createContext, useContext, useMemo, useState } from "react";

export interface GlobalContextType {
  animation: string;
  setAnimation: (value: string) => void;
}

export const GlobalContext = createContext<GlobalContextType>(
  {} as GlobalContextType
);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [animation, setAnimation] = useState<string>("HappyIdle");

  const value = useMemo(() => ({ animation, setAnimation }), [animation]);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
